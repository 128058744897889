<template>
  <div class="all">
    <Navbar class="tablet-hidden mobile-hidden" />
    <div class="page">
      <Header />

      <div v-show="loading" class="flex-center mt-3">
        <div class="loading2"></div>
      </div>
      <div class="home container">
        <div v-show="!isIos" class="grid">
          <div v-show="!loading" class="info-holder">
            <h1 class="title color-secondary">
              {{ t("Informações pessoais") }}
            </h1>
            <div class="inputs-holder">
              <div class="input-item">
                <span>{{ t("Nome completo") }} *</span>
                <input type="text" v-model="user.name" />
              </div>
              <div class="input-item">
                <span>E-mail *</span>
                <input type="text" v-model="user.email" />
              </div>
              <div class="input-item">
                <span>{{ t("Telefone/celular") }} *</span>
                <the-mask
                  v-model="user.phone"
                  :mask="['(##) ####-####', '(##) #####-####']"
                  :masked="false"
                />
              </div>
              <div class="input-item">
                <span>{{ t("CPF") }} *</span>
                <the-mask
                  v-model="user.cpf"
                  :mask="['###.###.###-##', '##.###.###/####-##']"
                  :masked="false"
                  readonly
                />
              </div>
              <div class="input-item">
                <span>{{ t("Data de nascimento") }} *</span>
                <the-mask
                  v-model="user.birthday"
                  placeholder="DD/MM/AAAA"
                  :mask="['##/##/####']"
                  :masked="true"
                />
              </div>
              <div class="input-item">
                <span>{{ t("Sexo") }} *</span>
                <select v-model="user.gender">
                  <option value="female">{{ t("Feminino") }}</option>
                  <option value="male">{{ t("Masculino") }}</option>
                </select>
              </div>
              <div class="input-item">
                <span>{{ t("Profissão") }} *</span>
                <input type="text" v-model="user.career" />
              </div>
              <div class="input-item">
                <span>{{ t("Idioma") }} *</span>
                <select v-model="user.language">
                  <option value>{{ t("Selecione uma opção") }}</option>
                  <option value="Português">{{ t("Português") }}</option>
                  <option value="Espanhol">{{ t("Espanhol") }}</option>
                </select>
              </div>
              <div class="input-item">
                <span>{{ t("CEP") }} *</span>
                <the-mask
                  @blur.native="getCep()"
                  v-model="user.zipcode"
                  :mask="['#####-###']"
                  :masked="false"
                />
              </div>
              <div v-show="showFullAddress" class="input-item">
                <span>{{ t("UF") }} *</span>
                <input type="text" v-model="user.state" />
              </div>
              <div v-show="showFullAddress" class="input-item">
                <span>{{ t("Cidade") }} *</span>
                <input type="text" v-model="user.city" />
              </div>
              <div v-show="showFullAddress" class="input-item">
                <span>{{ t("Bairro") }} *</span>
                <input type="text" v-model="user.neighborhood" />
              </div>
              <div v-show="showFullAddress" class="input-item">
                <span>{{ t("Endereço") }} *</span>
                <input type="text" v-model="user.address" />
              </div>
              <div v-show="showFullAddress" class="input-item">
                <span>{{ t("Número") }} *</span>
                <input type="text" v-model="user.number" />
              </div>
              <div v-show="showFullAddress" class="input-item">
                <span>{{ t("Complemento") }}</span>
                <input type="text" v-model="user.complement" />
              </div>
            </div>
            <transition enter-active-class="animated fadeIn">
              <p
                v-show="warningMessage != ''"
                class="warning text-center mt-2"
                style="position: relative; display: block"
              >
                {{ warningMessage }}
              </p>
            </transition>
            <div class="btn-holder">
              <div class="btn" @click="putUser">
                <span v-show="!saving">{{ t("Atualizar") }}</span>
                <div v-show="saving" class="loading2 white"></div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="!isIos" class="grid">
          <div v-show="!loading" class="info-holder mt-3-mobile">
            <h1 class="title color-secondary">{{ t("Trocar senha") }}</h1>
            <div class="inputs-holder">
              <div class="input-item">
                <span>{{ t("Senha atual") }} *</span>
                <input type="password" v-model="password" />
              </div>
            </div>
            <div class="inputs-holder">
              <div class="input-item">
                <span>{{ t("Nova senha") }} *</span>
                <input type="password" v-model="newPassword" />
              </div>
              <div class="input-item">
                <span>{{ t("Confirmar nova senha") }} *</span>
                <input type="password" v-model="newPassword2" />
              </div>
            </div>

            <transition enter-active-class="animated fadeIn">
              <p
                v-show="warningMessagePwd != ''"
                class="warning text-center mt-2"
                style="position: relative; display: block"
              >
                {{ warningMessagePwd }}
              </p>
            </transition>

            <div class="btn-holder">
              <div class="btn" @click="putPassword">
                <span v-show="!savingPassword">{{ t("Alterar senha") }}</span>
                <div v-show="savingPassword" class="loading2 white"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid">
          <div v-show="!loading" class="info-holder mt-3-mobile">
            <h1
              class="title color-secondary"
              style="-webkit-text-fill-color: initial"
            >
              {{ t("Excluir conta") }}
            </h1>
            <div class="delete-obs">
              Ao excluir sua conta você perderá acesso ao aplicativo e todos os
              seus dados serão excluídos e não poderão ser recuperados.
            </div>

            <transition enter-active-class="animated fadeIn">
              <p
                v-show="warningMessagePwd != ''"
                class="warning text-center mt-2"
                style="position: relative; display: block"
              >
                {{ warningMessagePwd }}
              </p>
            </transition>

            <div class="btn-holder">
              <div class="btn delete" @click="deleteAccount">
                <span v-show="!savingPassword">{{
                  t("Excluir minha conta")
                }}</span>
                <div v-show="savingPassword" class="loading2 white"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/http";
import authentication from "../util/authentication";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import cep from "cep-promise";
import validators from "../util/validators";
import platform from "../util/platform";

export default {
  components: {
    Navbar,
    Header,
  },
  data() {
    return {
      loading: true,
      saving: false,
      savingPassword: false,
      user: {
        name: "",
        email: "",
        phone: "",
        cpf: "",
        zipcode: "",
        state: "",
        city: "",
        neighborhood: "",
        address: "",
        number: "",
        complement: "",
        birthday: "",
        gender: "",
        career: "",
        language: "",
      },
      showFullAddress: false,
      password: "",
      newPassword: "",
      newPassword2: "",
      warningMessage: "",
      warningMessagePwd: "",
    };
  },
  created() {
    this.getUser();
  },
  computed: {
    isIos() {
      return platform.isIos();
    },
  },
  methods: {
    putPassword() {
      if (this.password == "") {
        this.warningMessagePwd =
          this.$translate.locale["Por favor, informe a sua senha atual"];
        return;
      }
      if (this.newPassword == "") {
        this.warningMessagePwd =
          this.$translate.locale["Por favor, informe a sua senha nova"];
        return;
      }
      if (this.newPassword2 == "") {
        this.warningMessagePwd =
          this.$translate.locale["Por favor, confirme a sua senha nova"];
        return;
      }
      if (this.newPassword !== this.newPassword2) {
        this.warningMessagePwd =
          this.$translate.locale[
            "Sua nova senha está diferente da confirmação de senha."
          ];
        return;
      }

      this.warningMessagePwd = "";
      this.savingPassword = true;

      http
        .put(
          "user/changePassword",
          {
            password: this.password,
            newPassword: this.newPassword,
          },
          authentication.getHeaders()
        )
        .then(() => {
          this.updateSuccess = true;
          this.password = "";
          this.newPassword = "";
          this.newPassword2 = "";
          this.warningMessagePwd =
            this.$translate.locale["Senha atualizada com sucesso!"];
        })
        .catch((err) => {
          this.warningMessagePwd = err.response.data;
        })
        .finally(() => {
          this.savingPassword = false;
        });
    },
    putUser() {
      if (!validators.validateFullName(this.user.name)) {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o seu nome completo"];
        return;
      }
      if (!validators.validateEmail(this.user.email)) {
        this.warningMessage =
          this.$translate.locale["Por favor, informe um e-mail válido"];
        return;
      }
      if (
        !validators.validateCPF(this.user.cpf) &&
        !authentication.getUser().info.premium
      ) {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o seu cpf corretamente"];
        return;
      }
      if (this.user.birthday == "") {
        this.warningMessage =
          this.$translate.locale["Por favor, informe sua data de nascimento"];
        return;
      }
      if (this.user.phone == "") {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe um número de telefone ou celular"
          ];
        return;
      }
      if (!this.user.zipcode && !authentication.getUser().info.premium) {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o seu CEP"];
        return;
      }
      if (!this.user.state && !authentication.getUser().info.premium) {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o seu estado"];
        return;
      }
      if (!this.user.city && !authentication.getUser().info.premium) {
        this.warningMessage =
          this.$translate.locale["Por favor, informe a sua cidade"];
        return;
      }
      if (!this.user.neighborhood && !authentication.getUser().info.premium) {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o seu bairro"];
        return;
      }
      if (!this.user.address && !authentication.getUser().info.premium) {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o seu endereço"];
        return;
      }
      if (this.user.career == "") {
        this.warningMessage =
          this.$translate.locale["Por favor, informe a sua profissão"];
        return;
      }

      this.warningMessage = "";
      this.saving = true;
      this.user.birthday = this.$moment(
        this.user.birthday,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DDTHH:mm:ss");
      http
        .put("user", this.user, authentication.getHeaders())
        .then((response) => {
          if (response.status === 200) {
            this.user = response.data;
            this.user.birthday = this.$moment(this.user.birthday).format(
              "DD/MM/YYYY"
            );
            let objUser = JSON.parse(localStorage.user);
            objUser.info = this.user;
            localStorage.user = JSON.stringify(objUser);

            this.warningMessage =
              this.$translate.locale["Dados atualizados com sucesso!"];
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    getUser() {
      this.loading = true;
      http
        .get("user/details", authentication.getHeaders())
        .then((response) => {
          this.user = response.data;
          this.user.birthday = this.$moment(this.user.birthday).format(
            "DD/MM/YYYY"
          );
          this.showFullAddress = this.user.zipcode ? true : false;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCep() {
      if (this.user.zipcode.length != 8) {
        this.$swal({
          type: "error",
          title: `${this.$translate.locale["CEP"]} *`,
          text: this.$translate.locale["Por favor, informe um CEP válido."],
        });
        return;
      }
      cep(this.user.zipcode)
        .then((response) => {
          this.user.state = response.state;
          this.user.city = response.city;
          this.user.address = response.street;
          this.user.neighborhood = response.neighborhood;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.showFullAddress = true;
        });
    },
    save() {
      this.saving = true;
      setTimeout(() => {
        this.saving = false;
      }, 1000);
    },
    deleteAccount() {
      this.$swal
        .fire({
          icon: "error",
          title: "Tem certeza que deseja excluir sua conta?",
          html: "<b>Essa ação não poderá ser desfeita!</b>",
          confirmButtonText: "Sim, excluir",
          confirmButtonColor: "var(--red1)",
          cancelButtonText: "Cancelar",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.loading = true;
            http
              .delete(
                "User/" + authentication.getUser().info.id,
                authentication.getHeaders()
              )
              .then(() => {
                authentication.logout();
              })
              .catch(() => {
                this.$swal({
                  icon: "warning",
                  text: "Não foi possível excluir a conta. Por favor, tente novamente mais tarde.",
                });
              })
              .finally(() => {
                this.loading = false;
              });
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.delete-obs {
  margin-top: 20px;
  font-size: 1.2em;
}

.page {
  position: relative;
  padding-bottom: 45px;
}

.home.container {
  width: 90%;
  max-width: initial;
}

.grid {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  margin: 25px 0 0 0;
  gap: 0 3.5rem;
  @media screen and (max-width: 1139px) {
    display: block;
  }
}

.info-holder {
  position: relative;
  box-shadow: 0 25px 35px rgba(18, 18, 44, 0.2);
  border-radius: 25px;
  padding: 35px 25px 25px 25px;
  margin-bottom: 25px;
  .btn-holder {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    .btn.delete {
      background-color: var(--red1);
      border-color: var(--red1);
    }
  }
}

.inputs-holder {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px 20px;
  margin-top: 25px;
}
</style>
